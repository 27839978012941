import React, { useState } from "react"
// import Backdrop from "./Backdrop"
// import { Modal } from "./styles"
import { motion } from "framer-motion"
import MenuContent from "./MenuContent"

// const slider = {
//   hidden: {
//     x: "100%",
//     // opacity: 0,
//   },
//   visible: {
//     x: "0",
//     transition: {
//       // duration: 1,
//       type: "tween",
//       ease: "backInOut",
//       // ease: [0.17, 0.67, 0.83, 0.67],
//     },
//     // transition: { type: "inertia", velocity: 50 },
//   },
//   exit: {
//     // x: "100%",
//     x: "0%",
//     transition: {
//       // duration: 1,
//       type: "tween",
//       ease: "backInOut",
//       // ease: [0.17, 0.67, 0.83, 0.67],
//     },
//   },
// }
const slider = {
  hidden: {
    x: "100%",
    // opacity: 0,
  },
  visible: {
    x: "0",
    transition: {
      // duration: 19,
      // ----- tween -----
      // type: "tween",
      // ease: "backInOut",
      // ease: [0.17, 0.67, 0.83, 0.67],
      // ----- spring -----
      type: "spring",
      bounce: 0,
    },
  },
  exit: {
    x: "100%",
  },
}

export default function Menu({ handleClose }) {
  const [loading, setLoading] = useState(false)

  const active = true

  return (
    <>
      {/* BACKDROP */}
      <motion.div
        className="backdrop"
        onClick={handleClose}
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
          transition: {
            type: "tween",
            ease: "backInOut",
            // duration: 1,
          },
        }}
        exit={{
          opacity: 0,
          transition: {
            type: "tween",
            ease: "backInOut",
            // duration: 1,
          },
        }}
      >
        {/* MODAL */}
        <motion.div
          className="modal"
          onClick={e => e.stopPropagation()} // Prevent click from closing moda
          variants={slider}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          {/* <button onClick={handleClose}>Close</button> */}
          <MenuContent handleClose={handleClose} />
        </motion.div>
      </motion.div>
    </>
  )
}
