import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import gsap from "gsap"
import { navigate } from "gatsby"
// import { useAuth } from "../lib/firebase/firebaseCentral";
import Media from "react-media"
import MobileMenu from "./MobileMenu"
import Menu from "../components/Menu"
import toast from "react-hot-toast"
import GoTrue from "gotrue-js"
import firebase from "gatsby-plugin-firebase"
import { AnimatePresence } from "framer-motion"
// import { ReactComponent as SunsetIcon } from "../images/emblem.svg"
// import { CSSTransition } from "react-transition-group"

const Header = ({ location }) => {
  const [activeMenu, setActiveMenu] = useState(null)
  // console.log("useAuth--Header", useAuth());
  // console.log("typeof useAuth--Header", typeof useAuth);
  // const { firebase } = useFirebaseArsenal();
  const [user, setUser] = useState(null)
  const [identity, setIdentity] = useState(null)
  // const { user, signout } = useAuth();

  /* 
  ---------------------------------------------
  */
  const [modalOpen, setModalOpen] = useState(false)

  const open = () => setModalOpen(true)
  const close = () => {
    setModalOpen(false)
  }
  /* 
  ---------------------------------------------
  */

  // -------- MOBILE MENU -----------
  // State of our Menu
  const [mobileMenu, setMobileMenu] = useState({
    initial: false,
    clicked: false,
    menuName: "MENU",
  })

  // State of our button
  const [disabled, setDisabled] = useState(false)

  // Toggle menu
  const handleMenu = () => {
    disableMenu()
    if (mobileMenu.initial === false) {
      setMobileMenu({
        initial: null,
        clicked: true,
        menuName: "CLOSE",
      })
    } else if (mobileMenu.clicked === true) {
      setMobileMenu({
        clicked: !mobileMenu.clicked,
        menuName: "MENU",
      })
    } else if (mobileMenu.clicked === false) {
      setMobileMenu({
        clicked: !mobileMenu.clicked,
        menuName: "CLOSE",
      })
    }
  }

  useEffect(() => {
    const auth = new GoTrue({
      APIUrl: "https://www.berserkers.dev/.netlify/identity",
      audience: "",
      // setCookie(optional): set to be false by default. If you wish to implement the remember me functionality, set the value to be true.
      setCookie: false,
    })
    // console.log("auth", auth);
    setIdentity(auth)
  }, [])

  useEffect(() => {
    if (identity?.currentUser()?.email) {
      if (
        identity &&
        identity.currentUser &&
        identity.currentUser() &&
        identity.currentUser().email
      ) {
        const e = identity.currentUser().email
        // console.log("e", e);
        getUser(e)
      }

      async function getUser(e) {
        // console.log("e", e);
        const userRef = firebase
          .firestore()
          .collection("users")
          // .doc(identity.currentUser().email);
          .doc(e)
        const userDoc = await userRef.get()
        const userData = await userDoc.data()
        setUser(userData)
      }
    }
  }, [identity])

  useEffect(() => {
    if (identity && location?.href) {
      getToken()
    }

    function getToken() {
      // console.log("location", location)
      // let token = await decodeURIComponent(window.location.search)

      let token = decodeURIComponent(location.href)
        .substring(1)
        .split("confirmation_token=")[1]
      // console.log("token", token)
      if (!token) {
        return false
      } else {
        identity
          .confirm(token)
          .then(response => {
            token = null
            // console.log("User has been successfully confirmed!", response)
            toast.success(
              "Congratulations! You're in. Please go ahead and login."
            )
            navigate("/login")
          })
          .catch(error => {
            toast.error("Something went wrong. Please contact us.")
            // console.log(error)
          })
      }
    }
  }, [identity])

  // Determine if our menu button should be disabled
  const disableMenu = () => {
    setDisabled(!disabled)
    setTimeout(() => {
      setDisabled(false)
    }, 1200)
  }

  // -------- MOBILE MENU ABOVE -----------
  const handleLogout = () => {
    // console.log("handled!", identity);
    // signout();

    const currentUser = identity.currentUser()

    currentUser
      .logout()
      .then(response => {
        // console.log("logout | response", response);
        toast.success("You have logged out!")
        navigate("/login")
      })
      .catch(error => {
        // console.log("Failed to logout user: %o", error);
        toast.success("Unable to login. Refresh, and try again.")
        throw error
      })
  }
  // console.log("handlelogout", handleLogout)

  React.useEffect(() => {
    gsap.set(".header-cloak", { autoAlpha: 1 })

    gsap.from(".header-cloak", {
      autoAlpha: 0,
      // scale: 0.25,
      // scale: 0.05,
      // delay: 1.75,
      delay: 1.5,
      // delay: 2,
      // rotation: 400,
      // rotation: 90,
      // y: -5,
      // y: 2,
      // x: 10,
      duration: 1,
      // x: 40,
      ease: "power3.inOut",
      // stagger: {
      // grid: [7, 15],
      // from: "end",
      // amount: 0.25,
      // amount: 0.15,
      // },
    })
  }, [])

  // React.useEffect(() => {
  //   if (firebase) {
  // console.log("s")
  // const unsubscribe = firebase.db.collection("test").onSnapshot((data) => {
  //   const res = []
  //   data.docs.forEach((doc) => {
  //     res.push({ ...doc.data(), id: doc.id })
  //   })
  // })
  // const unsubscribe = firebase.testSubscription({
  //   snapshotFn: data => {
  //     const res = []
  //     data.docs.forEach(doc => {
  //       res.push({ ...doc.data(), id: doc.id })
  //     })
  //     console.log("res", res)
  //   },
  // })
  // return () => {
  //   if (unsubscribe) {
  //     unsubscribe();
  //   }
  // };
  //   }
  // }, [firebase]);

  return (
    // <header className="header header-cloak">
    //   <div className="logo">
    //     <svg
    //       data-name="Layer 1"
    //       xmlns="http://www.w3.org/2000/svg"
    //       viewBox="0 0 231.01 30.2"
    //     >
    //       <path d="M21.09 21.02c0 5.25-3.55 8.77-10.21 8.77H0V.42h11.08c4.84 0 8.11 3.06 8.11 7.11a6.36 6.36 0 01-3.64 5.84 7.48 7.48 0 015.54 7.65zM6.53 6.13v5.46H9.8c2.11 0 3.1-1.16 3.1-2.78s-1.11-2.68-3.22-2.68zm8.07 14.23c0-2.2-1.28-3.73-4.22-3.73H6.53v7.45h3.93c2.86 0 4.14-1.45 4.14-3.72zM41.53 23.79v6H24.16V.42h17v6H30.69v5.62h8.77v5.92h-8.77v5.83zM54.92 20.31h-3.55v9.48h-6.54V.42H55.5c6.25 0 10.55 3.93 10.55 10.17a9 9 0 01-5.13 8.6l6.7 10.59h-6.9zm-3.55-5.58h3.47c2.65 0 4.59-1 4.59-4.14s-1.94-4.17-4.59-4.17h-3.47zM68.38 22.84l5.3-2.44c.82 2 2.4 3.89 5.17 3.89 1.94 0 3.72-1 3.72-3.23 0-1.74-.95-2.57-3.76-3.68l-1.82-.71c-3.93-1.57-7.53-3.72-7.53-8.48 0-5.08 4.26-8.19 9.35-8.19a10.33 10.33 0 019.68 6.25l-4.84 2.73c-1.29-2.23-2.77-3.19-4.59-3.19s-3.15.83-3.15 2.49c0 1.28.75 2.27 3.81 3.51l2 .79c5.3 2.11 7.66 4.51 7.66 8.56 0 6.12-5.46 9.06-10.43 9.06-5.19-.01-9.45-3.01-10.57-7.36zM109.59 23.79v6H92.21V.42h17v6H98.75v5.62h8.77v5.92h-8.77v5.83zM122.92 20.31h-3.56v9.48h-6.53V.42h10.67c6.24 0 10.55 3.93 10.55 10.17a9 9 0 01-5.13 8.6l6.7 10.59h-6.91zm-3.56-5.58h3.56c2.64 0 4.59-1 4.59-4.14s-1.95-4.17-4.59-4.17h-3.48zM147.69 18.41l-2.44 3v8.36h-6.54V.42h6.54v12.37L155.18.42h7.28l-10.92 13.32 11.83 16.05h-7.16zM183.18 23.79v6h-17.37V.42h17v6h-10.46v5.62h8.77v5.92h-8.77v5.83zM196.55 20.31h-3.56v9.48h-6.54V.42h10.68c6.24 0 10.54 3.93 10.54 10.17a9 9 0 01-5.13 8.61l6.71 10.59h-6.91zm-3.56-5.58h3.47c2.65 0 4.6-1 4.6-4.14s-1.95-4.17-4.6-4.17h-3.47zM210.03 22.84l5.3-2.44c.83 2 2.4 3.89 5.17 3.89 1.94 0 3.72-1 3.72-3.23 0-1.74-.95-2.57-3.76-3.68l-1.82-.71c-3.93-1.57-7.53-3.72-7.53-8.48 0-5.08 4.26-8.19 9.35-8.19a10.33 10.33 0 019.68 6.25l-4.84 2.73c-1.28-2.23-2.77-3.19-4.59-3.19s-3.15.83-3.15 2.49c0 1.28.75 2.27 3.81 3.51l2 .79c5.3 2.11 7.66 4.51 7.66 8.56 0 6.12-5.46 9.06-10.43 9.06-5.19-.01-9.45-3.01-10.57-7.36z" />
    //     </svg>
    //   </div>

    //   <nav className="navbar">
    //     <ul className="navbar__nav">

    //     <li>Products & Services</li>
    //     <li>Pricing</li>
    //     <li>Contact</li>
    //     </ul>
    //   </nav>
    // </header>
    <>
      <Navbar>
        <Link
          // className={`${mobileMenu.clicked === true ? "logo-active" : "logo"} `}
          className={`logo`}
          to="/"
        >
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 231.01 30.2"
          >
            <path d="M21.09 21.02c0 5.25-3.55 8.77-10.21 8.77H0V.42h11.08c4.84 0 8.11 3.06 8.11 7.11a6.36 6.36 0 01-3.64 5.84 7.48 7.48 0 015.54 7.65zM6.53 6.13v5.46H9.8c2.11 0 3.1-1.16 3.1-2.78s-1.11-2.68-3.22-2.68zm8.07 14.23c0-2.2-1.28-3.73-4.22-3.73H6.53v7.45h3.93c2.86 0 4.14-1.45 4.14-3.72zM41.53 23.79v6H24.16V.42h17v6H30.69v5.62h8.77v5.92h-8.77v5.83zM54.92 20.31h-3.55v9.48h-6.54V.42H55.5c6.25 0 10.55 3.93 10.55 10.17a9 9 0 01-5.13 8.6l6.7 10.59h-6.9zm-3.55-5.58h3.47c2.65 0 4.59-1 4.59-4.14s-1.94-4.17-4.59-4.17h-3.47zM68.38 22.84l5.3-2.44c.82 2 2.4 3.89 5.17 3.89 1.94 0 3.72-1 3.72-3.23 0-1.74-.95-2.57-3.76-3.68l-1.82-.71c-3.93-1.57-7.53-3.72-7.53-8.48 0-5.08 4.26-8.19 9.35-8.19a10.33 10.33 0 019.68 6.25l-4.84 2.73c-1.29-2.23-2.77-3.19-4.59-3.19s-3.15.83-3.15 2.49c0 1.28.75 2.27 3.81 3.51l2 .79c5.3 2.11 7.66 4.51 7.66 8.56 0 6.12-5.46 9.06-10.43 9.06-5.19-.01-9.45-3.01-10.57-7.36zM109.59 23.79v6H92.21V.42h17v6H98.75v5.62h8.77v5.92h-8.77v5.83zM122.92 20.31h-3.56v9.48h-6.53V.42h10.67c6.24 0 10.55 3.93 10.55 10.17a9 9 0 01-5.13 8.6l6.7 10.59h-6.91zm-3.56-5.58h3.56c2.64 0 4.59-1 4.59-4.14s-1.95-4.17-4.59-4.17h-3.48zM147.69 18.41l-2.44 3v8.36h-6.54V.42h6.54v12.37L155.18.42h7.28l-10.92 13.32 11.83 16.05h-7.16zM183.18 23.79v6h-17.37V.42h17v6h-10.46v5.62h8.77v5.92h-8.77v5.83zM196.55 20.31h-3.56v9.48h-6.54V.42h10.68c6.24 0 10.54 3.93 10.54 10.17a9 9 0 01-5.13 8.61l6.71 10.59h-6.91zm-3.56-5.58h3.47c2.65 0 4.6-1 4.6-4.14s-1.95-4.17-4.6-4.17h-3.47zM210.03 22.84l5.3-2.44c.83 2 2.4 3.89 5.17 3.89 1.94 0 3.72-1 3.72-3.23 0-1.74-.95-2.57-3.76-3.68l-1.82-.71c-3.93-1.57-7.53-3.72-7.53-8.48 0-5.08 4.26-8.19 9.35-8.19a10.33 10.33 0 019.68 6.25l-4.84 2.73c-1.28-2.23-2.77-3.19-4.59-3.19s-3.15.83-3.15 2.49c0 1.28.75 2.27 3.81 3.51l2 .79c5.3 2.11 7.66 4.51 7.66 8.56 0 6.12-5.46 9.06-10.43 9.06-5.19-.01-9.45-3.01-10.57-7.36z" />
          </svg>
        </Link>
        <AnimatePresence
          initial={false}
          // Disable any initial animations on children that
          // are present when the component is first rendered
          // Only render one component at a time.
          // The exiting component will finish its exit
          // animation before entering component is rendered
          exitBeforeEnter={true}
        >
          {modalOpen && <Menu handleClose={close} />}
        </AnimatePresence>

        <Media
          queries={{
            small: "(min-width: 850px)",
            // laptop: "(min-width: 1100px)",
            // desktop: "(min-width: 1600px)",
            // largeDesktop: "(min-width: 1920px)",
          }}
        >
          {matches =>
            matches.small ? (
              <div style={{ display: "flex" }}>
                {/* <NavItem
                  activeMenu={activeMenu}
                  setActiveMenu={setActiveMenu}
                  submenu="products"
                  icon="⚡"
                  item="PRODUCTS & SERVICES"
                >
                  {activeMenu === "products" && (
                    <DropdownMenu
                      submenu="products"
                      handleLogout={handleLogout}
                    />
                  )}
                </NavItem> */}

                <div
                  onClick={() => (modalOpen ? close() : open())}
                  className={`nav-item`}
                >
                  <div>PRODUCTS & SERVICES</div>
                </div>

                <Link to="/faq" className={`nav-item`}>
                  <div>FAQ</div>
                </Link>

                <Link to="/hello" className={`nav-item`}>
                  <div>CONTACT</div>
                </Link>

                {user?.email ? (
                  <NavItem
                    activeMenu={activeMenu}
                    setActiveMenu={setActiveMenu}
                    submenu="account"
                    icon="⚡"
                    item="ACCOUNT"
                  >
                    {activeMenu === "account" && (
                      <DropdownMenu
                        submenu="account"
                        handleLogout={handleLogout}
                      />
                    )}
                  </NavItem>
                ) : (
                  <Link to="/login" className={`nav-item`}>
                    LOGIN
                  </Link>
                )}
              </div>
            ) : (
              <>
                {/* <button
                  // className={`${
                  //   mobileMenu.clicked === true
                  //     ? "mobile-nav-active"
                  //     : "mobile-nav"
                  // } `}
                  className={`mobile-nav `}
                  disabled={disabled}
                  onClick={handleMenu}
                  // onClick={() => setMenuState(true)}
                >
                  {mobileMenu.menuName}
                </button> */}

                <div
                  onClick={() => (modalOpen ? close() : open())}
                  className={`nav-item`}
                >
                  <div>MENU</div>
                </div>

                {/* <MobileMenu
                  onClick={() => (modalOpen ? close() : open())}
                  // disabled={disabled}
                  menuState={mobileMenu}
                  // handleMenu={handleMenu}
                  color="#fff"
                /> */}
              </>
            )
          }
        </Media>
      </Navbar>
    </>
  )
}

const Navbar = props => {
  return (
    // <nav className="navbar header-cloak">
    <nav className="navbar">
      <ul className="navbar-nav">{props.children}</ul>
    </nav>
  )
}

const NavItem = props => {
  // const [open, setOpen] = useState(false)

  return (
    // <li className={`nav-item ${props.submenu === "products" && "link--ersa"} `}>
    <li
      className={`nav-item ${
        props.submenu === props.activeMenu && `nav-item-${props.submenu}`
      }`}
    >
      {/* <div href="#" className="icon-button" onClick={() => setOpen(!open)}> */}

      <div
        // className="icon-button"
        onClick={() =>
          props.submenu === props.activeMenu
            ? props.setActiveMenu(null)
            : props.setActiveMenu(props.submenu)
        }
      >
        {props.item}
      </div>

      {props.children}
    </li>
  )
}

const LogoutNavItem = props => {
  // const [open, setOpen] = useState(false)

  return (
    // <li className={`nav-item ${props.submenu === "products" && "link--ersa"} `}>
    <li
      className={`nav-item ${
        props.submenu === props.activeMenu && `nav-item-${props.submenu}`
      }`}
    >
      {/* <div href="#" className="icon-button" onClick={() => setOpen(!open)}> */}

      <div
        // className="icon-button"
        onClick={() =>
          props.submenu === props.activeMenu
            ? props.setActiveMenu(null)
            : props.setActiveMenu(props.submenu)
        }
      >
        {props.item}
      </div>

      {props.children}
    </li>
  )
}

const DropdownMenu = ({ submenu, handleLogout }) => {
  const [activeMenu, setActiveMenu] = useState("main")
  // console.log("---submenu", submenu)
  // console.log("---handleLogout", handleLogout)

  const DropdownItem = props => {
    return (
      <div
        className="menu-item"
        onClick={() => props.goToMenu && setActiveMenu(props.goToMenu)}
      >
        {/* <span className="icon-button">{props.leftIcon}</span> */}
        {props.children}
        {/* <span className="icon-right">{props.rightIcon}</span> */}
      </div>
    )
  }
  const DropdownLogoutItem = props => {
    // console.log("handleLogout", handleLogout);
    return (
      <div
        className="menu-item"
        // onClick={() => props.goToMenu && setActiveMenu(props.goToMenu)}
        // onClick={() => handleLogout()}
      >
        {/* <span className="icon-button">{props.leftIcon}</span> */}
        {props.children}
        {/* <span className="icon-right">{props.rightIcon}</span> */}
      </div>
    )
  }
  return (
    // <div className="dropdown" style={{ height: menuHeight }}>
    <>
      {submenu === "products" && (
        <div className="dropdown-ps">
          {/* <CSSTransition
        in={activeMenu === "main"}
        unmountOnExit
        timeout={500}
        classNames="menu-primary"
        onEnter={calcHeight}
      > */}

          <div className="menu">
            <Link style={{ color: "#fff" }} to="/berserkseo">
              <DropdownItem>Berserk SEO</DropdownItem>
            </Link>

            <Link style={{ color: "#fff" }} to="/in-house">
              <DropdownItem leftIcon={"✍"}>Done For You SEO</DropdownItem>
            </Link>
            <Link style={{ color: "#fff" }} to="/aw">
              <DropdownItem rightIcon={"⌛"}>Apps & Websites</DropdownItem>
            </Link>
          </div>

          {/* </CSSTransition> */}

          {/* <CSSTransition
        in={submenu === "settings"}
        unmountOnExit
        timeout={500}
        classNames="menu-secondary"
        onEnter={calcHeight}
      > */}
          {/* {submenu === "pricing" && (
        <div className="menu">
          <DropdownItem>Pricing</DropdownItem>
          <DropdownItem goToMenu="settings" leftIcon={"✍"}>
            My Profile
          </DropdownItem>
          <DropdownItem rightIcon={"⌛"}>My Profile</DropdownItem>
          <DropdownItem rightIcon={"⌛"}>My Profile</DropdownItem>
          <DropdownItem rightIcon={"⌛"}>My Profile</DropdownItem>
          <DropdownItem rightIcon={"⌛"}>My Profile</DropdownItem>
          <DropdownItem rightIcon={"⌛"}>My Profile</DropdownItem>
          <DropdownItem rightIcon={"⌛"}>My Profile</DropdownItem>
          <DropdownItem rightIcon={"⌛"}>My Profile</DropdownItem>
          <DropdownItem rightIcon={"⌛"}>My Profile</DropdownItem>
          <DropdownItem rightIcon={"⌛"}>My Profile</DropdownItem>
          <DropdownItem rightIcon={"⌛"}>My Profile</DropdownItem>
        </div>
      )} */}

          {/* </CSSTransition> */}
        </div>
      )}

      {submenu === "account" && (
        <div className="dropdown-login">
          {/* <CSSTransition
        in={activeMenu === "main"}
        unmountOnExit
        timeout={500}
        classNames="menu-primary"
        onEnter={calcHeight}
      > */}

          {/* <div className="menu">
            <DropdownItem>Berserk SEO</DropdownItem>
            <DropdownItem leftIcon={"✍"}>In-house SEO</DropdownItem>
            <DropdownItem rightIcon={"⌛"}>Apps & Websites</DropdownItem>
          </div> */}

          {/* </CSSTransition> */}

          {/* <CSSTransition
        in={submenu === "settings"}
        unmountOnExit
        timeout={500}
        classNames="menu-secondary"
        onEnter={calcHeight}
      > */}
          {/* {submenu === "pricing" && (
        <div className="menu">
          <DropdownItem>Pricing</DropdownItem>
          <DropdownItem goToMenu="settings" leftIcon={"✍"}>
            My Profile
          </DropdownItem>
          <DropdownItem rightIcon={"⌛"}>My Profile</DropdownItem>
          <DropdownItem rightIcon={"⌛"}>My Profile</DropdownItem>
          <DropdownItem rightIcon={"⌛"}>My Profile</DropdownItem>
          <DropdownItem rightIcon={"⌛"}>My Profile</DropdownItem>
          <DropdownItem rightIcon={"⌛"}>My Profile</DropdownItem>
          <DropdownItem rightIcon={"⌛"}>My Profile</DropdownItem>
          <DropdownItem rightIcon={"⌛"}>My Profile</DropdownItem>
          <DropdownItem rightIcon={"⌛"}>My Profile</DropdownItem>
          <DropdownItem rightIcon={"⌛"}>My Profile</DropdownItem>
          <DropdownItem rightIcon={"⌛"}>My Profile</DropdownItem>
        </div>
      )} */}

          <div className="menu">
            <Link style={{ color: "#fff" }} to="/dashboard">
              <DropdownItem>Dashboard</DropdownItem>
            </Link>
            <div onClick={() => handleLogout()}>
              <DropdownLogoutItem> Logout</DropdownLogoutItem>
            </div>
            {/* <DropdownItem goToMenu="settings" leftIcon={"✍"}>
            My Profile
          </DropdownItem> */}
          </div>

          {/* </CSSTransition> */}
        </div>
      )}
    </>
  )
}

export default Header
